import { LocalStorageStrategy, State } from '@/utility/state';
import { checkIsMobile, getDeviceData, getDeviceId, isTouchDevice, getThemeName } from './envUtility';

const apiHost: string = 'https://gagikpog-api.ru/films';
const localApiHost: string = 'http://films.gagikpog-linux.ru/films';
const productionHost: string = '';
const productionHostFull: string = 'https://films.gagikpog.ru';
const localHost: string = 'http://films.gagikpog-local.ru';

const state = State({useNewApi: false, isDebugApi: false}, 'env', new LocalStorageStrategy());
const isProduction: boolean = process.env.NODE_ENV !== 'development';

const prodHost: string = state.useNewApi ? apiHost : productionHost;
const debugHost: string = state.useNewApi ?
    (state.isDebugApi ? localApiHost : apiHost) :
    (state.isDebugApi ? localHost : productionHostFull);

const env = {
    host: isProduction ? prodHost : debugHost,
    hostForNewApiOnly: state.isDebugApi ? localApiHost : apiHost,
    proxy: '',
    defaultTitle: 'Films',
    hostRoot: '',
    apiPath: 'api',
    isProduction,
    isMobile: checkIsMobile(),
    isTouch: isTouchDevice(),
    deviceId: getDeviceId(),
    get theme(): TThemeName {
        return getThemeName();
    },
    ...getDeviceData()
};

window.setNewApi = (value: boolean = true): void => {
    state.useNewApi = Boolean(value);
    document.location.reload();
};

window.activateGlobalState = (): void => {
    Promise.all([
        import('@/utility/auth/session'),
        import('@/store')
    ]).then(([{session}, module]) => {
        // @ts-ignore
        window.session = session;
        // @ts-ignore
        window.store = module.default;
        // @ts-ignore
        window.env = env;
        console.info('Global state activated!');
    });
};

if (!isProduction) {
    window.setDebugApi = (value: boolean = true): void => {
        state.isDebugApi = Boolean(value);
        document.location.reload();
    };
}

export default env;